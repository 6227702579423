import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom"
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import Layout from "./components/Layout";
import Rehab from "./pages/Rehab";
import Ambulator from "./pages/Ambulator";
import Sozo from "./pages/Sozo";
import DariDobro from "./pages/DariDobro";

const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/rehab",
                element: <Rehab />,
            },
            {
                path: "/ambulator",
                element: <Ambulator />,
            },
            {
                path: "/sozo",
                element: <Sozo />,
            },
            {
                path: "/dari-dobro",
                element: <DariDobro />,
            },
            { 
                path: "*", element: <Home /> 
            },
        ],
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
