import { Outlet } from "react-router-dom"
import {slide as Menu} from "react-burger-menu";
import AOS from "aos";
import '.././App.css';
import {useCallback, useEffect, useState} from "react";
import Carousel from "react-multi-carousel";
AOS.init({
    once: true
});
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
export default function Layout() {
    const [loading, setLoading] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    function sendMessage() {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('message', message);
        fetch('/send.php', 
            {method: 'post', body: formData})
            .then(r  => {});
        alert(`${name} Спасибо за ваще сообщение!`);
    }
    const Loading = () => {
        if (loading === true) {
            return (
                <div className="loading">
                    <div className="bubble" />
                    <div className="bubble" />
                    <div className="bubble" />
                </div>
            );
        } else if (loading === false) {
            setLoading(null);
            return (
                <>
                    <div className="doneLoadingBG" />
                    <div className="doneLoadingLeft" />
                    <div className="doneLoadingRight" />
                </>
            );
        }
    };
    return (
        <>
            <Loading/>
            <div className="h-22 w-[99vw] py-4 px-2 fixed z-50 bg-white">
                <div><a href="/" className="cursor-pointer text-4xl md:text-5xl text-primary font-bold w-52"><span className="text-[#6e6e6e]">Re</span>forma<span
                    className="bg-primary text-white italic pr-2">ция</span></a></div>
            </div>
            <Menu right={true}>
                <a className="menu-item py-4" href="/">Главная</a>
                <a className="menu-item py-4" href="/rehab">Центр социальной адаптации</a>
                <a className="menu-item py-4" href="/ambulator">Амбулаторная программа реабилитации</a>
                <a className="menu-item--small py-4" href="/sozo">Группы для созависиммых</a>
                <a className="menu-item--small py-4 pb-8" href="/dari-dobro">Дари Добро</a>
                <hr/>
                <a className="menu-item--small py-4 font-bold" href="tel:+375291600500">+375 (29) 160-05-00</a>
                <a className="menu-item--small py-4 font-bold" href="tel:+375296468000">+375 (29) 646-80-00</a>
                <a className="menu-item--small py-4 font-bold" href="tel:+375291768982">+375 (29) 176-89-82</a>
                <a className="menu-item--small py-4 font-bold" href="tel:+375291714902">+375 (29) 171-49-02</a>
            </Menu>
            <div className="w-[99vw] h-[96vh]">
                <Outlet context={[loading, setLoading]}/>
                <div className="relative section mb-20 md:mb-24">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6"
                         >
                        <div className="mx-auto mb-2 max-w-screen-sm">
                            <h2 className="mb-4 text-4xl tracking-tight text-white-900 md:text-4xl block-header">Наша
                                команда</h2>
                            <p className="text-lg mt-8">Волонтеры СБУ «Реформация личности» имеют многолетний опыт работы с зависимыми людьми</p>
                        </div>
                        <Carousel responsive={responsive} className="md:py-12">
                            <div className="text-center text-white-500 dark:text-white-400">
                                <img className="mx-auto mb-4 h-36 rounded-full"
                                     src="/media/team/elena.png"
                                     alt="Bonnie Avatar"/>
                                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white-900">
                                    <a href="#">Елена Котельникова</a>
                                </h3>
                                <p className="text-primary">Руководитель СБУ «Реформация личности», психолог. Более 20 лет опыта работы с зависимыми людьми</p>
                            </div>
                            <div className="text-center text-white-500 dark:text-white-400">
                                <img className="mx-auto mb-4 h-36 rounded-full"
                                     src="/media/team/max.png"
                                     alt="Helene Avatar"/>
                                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white-900">
                                    <a href="#">Максим Зялик</a>
                                </h3>
                                <p className="text-primary">Куратор амбулаторной программы реабилитации «Путь к свободе»</p>
                            </div>
                            <div className="text-center text-white-500 dark:text-white-400">
                            <img className="mx-auto mb-4 h-36 rounded-full"
                                     src="/media/team/pavel.png"
                                     alt="Bonnie Avatar"/>
                                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white-900">
                                    <a href="#">Павел Тереш</a>
                                </h3>
                                <p className="text-primary">Психолог-консультант, куратор группы для созависимых</p>
                            </div>
                            <div className="text-center text-white-500 dark:text-white-400">
                                <img className="mx-auto mb-4 h-36 rounded-full"
                                     src="/media/team/ash.png"
                                     alt="Jese Avatar"/>
                                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white-900">
                                    <a href="#">Андрей Шиковец</a>
                                </h3>
                                <p className="text-primary">Директор центра исцеления и восстановления личности «Реформация»</p>
                            </div>
                            <div className="text-center text-white-500 dark:text-white-400">
                                <img className="mx-auto mb-4 h-36 rounded-full"
                                     src="/media/team/anton.png"
                                     alt="Joseph Avatar"/>
                                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white-900">
                                    <a href="#">Антон Занкович</a>
                                </h3>
                                <p className="text-primary">Волонтёр</p>
                            </div>
                            <div className="text-center text-white-500 dark:text-white-400">
                                <img className="mx-auto mb-4 h-36 rounded-full"
                                     src="/media/team/gleb.png"
                                     alt="Sofia Avatar"/>
                                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white-900">
                                    <a href="#">Глеб Левкович</a>
                                </h3>
                                <p className="text-primary">Волонтёр</p>
                            </div>
                            <div className="text-center text-white-500 dark:text-white-400">
                                <img className="mx-auto mb-4 h-36 rounded-full"
                                     src="/media/team/andrei_b.png"
                                     alt="Leslie Avatar"/>
                                <h3 className="mb-1 text-2xl font-bold tracking-tight text-white-900">
                                    <a href="#">Андрей Борщевский</a>
                                </h3>
                                <p className="text-primary">Волонтёр</p>
                            </div>
                        </Carousel>
                    </div>
                </div>
                <div className="section">
                    <div className="container mx-auto md:px-6 max-w-screen-xl">
                        <section className="mb-32">
                            <div className="flex justify-center">
                                <div className="text-center md:max-w-xl lg:max-w-3xl">
                                    <h2 className="mb-12 px-6 text-4xl block-header">Контакты</h2>
                                </div>
                            </div>
                            <div className="flex flex-wrap">
                                <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                                <div className="flex flex-wrap">
                                        <div
                                            className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                                            <div className="flex items-start">
                                                <div className="shrink-0">
                                                    <div
                                                        className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth="2"
                                                             stroke="currentColor" className="h-6 w-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="ml-6 grow">
                                                    <p className="mb-2 font-bold">
                                                        Елена
                                                    </p>
                                                    <p className="text-neutral-500">
                                                        <a href="tel:+375291600500">+375 (29) 160-05-00</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                                            <div className="flex items-start">
                                                <div className="shrink-0">
                                                    <div
                                                        className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth="2"
                                                             stroke="currentColor" className="h-6 w-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="ml-6 grow">
                                                    <p className="mb-2 font-bold">
                                                        Максим
                                                    </p>
                                                    <p className="text-neutral-500">
                                                        <a href="tel:+375291768982">+375 (29) 176-89-82</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                                            <div className="align-start flex">
                                                <div className="shrink-0">
                                                    <div
                                                        className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth="2"
                                                             stroke="currentColor" className="h-6 w-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="ml-6 grow">
                                                    <p className="mb-2 font-bold">Андрей</p>
                                                    <p className="text-neutral-500">
                                                        <a href="tel:+375296468000">+375 (29) 646-80-00</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                                            <div className="align-start flex">
                                                <div className="shrink-0">
                                                    <div
                                                        className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth="2"
                                                             stroke="currentColor" className="h-6 w-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="ml-6 grow">
                                                    <p className="mb-2 font-bold">Павел</p>
                                                    <p className="text-neutral-500">
                                                        <a href="tel:+375291714902">+375 (29) 171-49-02</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6">
                                    <form>
                                        <div className="relative mb-6">
                                            <input type="text" value={name} onChange={event => {
                                                setName(
                                                    event.target.value
                                                );
                                            }}
                                                   className="peer block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                                   id="exampleInput90" placeholder="Name"/>
                                            <label
                                                className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] transition-all duration-200 ease-out text-primary -translate-y-[0.9rem] scale-[0.8]"
                                                htmlFor="exampleInput90">Ваше Имя
                                            </label>
                                        </div>
                                        <div className="relative mb-6">
                                            <input type="email" value={email}
                                                   onChange={event => {
                                                setEmail(
                                                    event.target.value
                                                );
                                            }}
                                                   className="peer block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                                                   id="exampleInput91" placeholder="Email address"/>
                                            <label
                                                className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] transition-all duration-200 ease-out text-primary -translate-y-[0.9rem] scale-[0.8]"
                                                htmlFor="exampleInput91">Электронная почта или Телефон
                                            </label>
                                        </div>
                                        <div className="relative mb-6">
                <textarea  value={message} onChange={event => {
                    setMessage(
                        event.target.value
                    );
                }}
                    className="peer block min-h-[auto] w-full rounded border-0 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                    id="exampleFormControlTextarea1" rows="3" placeholder="Ваще сообщение"></textarea>
                                            <label htmlFor="exampleFormControlTextarea1"
                                                   className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] transition-all duration-200 ease-out text-primary -translate-y-[0.9rem] scale-[0.8]">Сообщение</label>
                                        </div>
                                        <button onClick={() => sendMessage()} type="button" data-te-ripple-color="light"
                                                className="mb-6 inline-block w-full rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                                            Отправить
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}