import '.././App.css';
import {useEffect} from "react";
import "../../node_modules/aos/dist/aos.css";
import CountUp from "react-countup";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useOutletContext} from "react-router-dom";
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function DariDobro() {
  return (
      <>
          <div className="relative section mb-2 md:mb-6">
              <div className="overflow-hidden text-white h-[54vh] md:h-[74vh]">
                  <div className="relative h-[100vh]">
                      <video id="mainVideo" aria-hidden="true" muted
                             className="relative z-20 h-full w-full object-cover transition-opacity duration-300 pointer visible opacity-100"
                             autoPlay loop playsInline>
                          <source
                              src="/media/daridobro.mp4"
                              type="video/mp4; codecs=avc1"/>
                          Your browser does not support the video tag.
                      </video>
                  </div>
                  <div className="absolute top-0 right-0 bottom-0 left-0 z-40 pointer-events-none bg-[rgba(0,0,0,0.2)]">
                      <div className="container relative md:top-[60%] top-[65%] left-3 right-0 w-[97vw]">
                          <div
                              className="z-40 xs:w-6-cols md:w-8-cols lg:ml-2-cols lg:w-8-cols text-white max-[1280px]:pt-12 pt-48 md:pl-24">
                              <h2 className="text-4xl max-[1280px]:text-4xl inline-block md:ml-52">
                              </h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="relative section my-8 md:my-20 md:mb-24 mx-2 md:mx-0">
              <div className="container mx-auto max-w-screen-xl">
                  <div className="flex items-center mb-4 md:mb-12">
                      <h1 className="mb-4 text-4xl tracking-tight text-white-900 block-header text-left">
                          Дари добро
                      </h1>
                  </div>
                  <h1 className="aos text-xl leading-8 md:leading-10">
                      <div className="mb-6 md:mb-12 text-left">
                          СБУ «Реформация личности» помогает людям, попавшим в трудную жизненную ситуацию, предоставляя
                          им помощь в виде продуктовых пакетов.

                          Мы поддерживаем малообеспеченные семьи, пенсионеров, инвалидов, людей без определенного места
                          жительства, бывших заключенных и тд.
                      </div>
                  </h1>
              </div>
          </div>

          <div className="section flex flex-col justify-center items-center w-full text-white  mb-20 md:mb-24">
              <div className="w-full">
                  <Carousel responsive={responsive}>
                      <div className="max-h-[300px]">
                          <img
                              src="/media/daridobro/5.jpg"
                              alt="image 1"
                              className="max-w-full object-cover px-1"
                          />
                      </div>
                      <div className="max-h-[300px]">
                          <img
                              src="/media/daridobro/1.jpg"
                              alt="image 1"
                              className="max-w-full object-cover px-1"
                          />
                      </div>
                      <div className="max-h-[300px]">
                          <img
                              src="/media/daridobro/3.jpg"
                              alt="image 1"
                              className="max-w-full object-cover px-1"
                          />
                      </div>
                      <div className="max-h-[300px]">
                          <img
                              src="/media/daridobro/4.jpg"
                              alt="image 1"
                              className="max-w-full object-cover px-1"
                          />
                      </div>
                      <div className="max-h-[300px]">
                          <img
                              src="/media/daridobro/6.jpg"
                              alt="image 1"
                              className="max-w-full object-cover px-1"
                          />
                      </div>
                  </Carousel>
              </div>
          </div>
      </>
  );
}

export default DariDobro;
