import '.././App.css';
import {useEffect} from "react";
import "../../node_modules/aos/dist/aos.css";
import CountUp from "react-countup";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useOutletContext} from "react-router-dom";
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function Ambulator() {
  return (
      <>
          <div className="relative section mb-2 md:mb-6">
              <div className="overflow-hidden text-white h-[54vh]">
                  <div className="relative h-[100vh]">
                      <video id="mainVideo" aria-hidden="true" muted
                             className="relative z-20 h-full w-full object-cover transition-opacity duration-300 pointer visible opacity-100"
                             autoPlay loop playsInline>
                          <source
                              src="/media/ambulator.mp4"
                              type="video/mp4; codecs=avc1"/>
                          Your browser does not support the video tag.
                      </video>
                  </div>
                  <div className="absolute top-0 right-0 bottom-0 left-0 z-40 pointer-events-none bg-[rgba(0,0,0,0.2)]">
                      <div className="container relative md:top-[45%] top-[65%] left-3 right-0 w-[97vw]">
                          <div
                              className="z-40 xs:w-6-cols md:w-8-cols lg:ml-2-cols lg:w-8-cols text-white max-[1280px]:pt-12 pt-44 md:pl-24">
                              <h2 className="text-4xl max-[1280px]:text-4xl inline-block md:ml-52">
                                  
                              </h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="relative section my-8 md:my-20 md:mb-24 mx-2 md:mx-0">
              <div className="container mx-auto max-w-screen-xl">
                  <div className="flex items-center mb-4 md:mb-12">
                      <h1 className="mb-4 text-4xl tracking-tight text-white-900 block-header text-left">
                          Амбулаторная программа реабилитации
                      </h1>
                  </div>
                  <h1 className="aos text-xl leading-8 md:leading-10">
                      <div className="mb-6 md:mb-12 text-left">
                          В рамках деятельности СБУ «Реформация личности» проходит программа амбулаторной реабилитации
                          «Путь к свободе», где все нуждающиеся могут получить бесплатную квалифицированную помощь в
                          борьбе с наркотической и алкогольной зависимостью, депрессией и другими проблемами.

                          Программа включает в себя 10 шагов, прохождение которых длится в среднем 1 год. Люди,
                          проходящие амбулаторную программу реабилитации, посещают ее <span
                          className="font-bold text-primary">3 раза в неделю (понедельник, среда, пятница) с 18:30 до 20:30 по адресу: г. Минск, ул. Чеботарева, 6а</span>
                      </div>
                  </h1>
              </div>
          </div>

          <div className="section flex flex-col justify-center items-center w-full text-white mb-20 md:mb-24">
              <div className="w-full">
                  <Carousel responsive={responsive}>
                      <img
                          src="/media/ambulator/3.jpg"
                          alt="image 3"
                          className="h-auto max-w-full object-cover px-1"
                      />

                      <img
                          src="/media/ambulator/4.jpg"
                          alt="image 3"
                          className="h-auto max-w-full object-cover px-1"
                      />
                      <img
                          src="/media/ambulator/5.jpg"
                          alt="image 3"
                          className="h-auto max-w-full object-cover px-1"
                      />
                      <img
                          src="/media/ambulator/2.jpg"
                          alt="image 2"
                          className="h-auto max-w-full object-cover px-1"
                      />
                      <img
                          src="/media/ambulator/1.jpg"
                          alt="image 1"
                          className="h-auto max-w-full object-cover px-1"
                      />
                  </Carousel>
              </div>
          </div>
      </>
  );
}

export default Ambulator;
